import React from "react";

import GeneralContactForm from "../../../../ContactForm";

const ContactForm = ({ name = "oilfox" }) => (
    <GeneralContactForm
        phoneHeadline="0 42 62 - 797"
        phoneLink="+494262797"
        emailHeadline="oilfox@hoyer.de"
        urlEnding="oilfox"
        pushToGTMString={`${name}:contact-form`}
        canSendStorageString={`${name}::contact-form:sent-at`}
    />
);

export default ContactForm;
